.google-map-marker {
    position: relative;
    border: 1px solid;
    background: white;
}

.google-map-marker:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid;
    position: absolute;
    top: 30px;
    left: 5.5px;
}